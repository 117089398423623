<template>
  <div>
    <a-row type="flex">
      <a-col flex="1 1 200px">
        <a-typography-title :level="2">Employees</a-typography-title>
      </a-col>
      <a-col flex="0 1 200px">
        <a-button
          size="large"
          type="primary"
          block
          danger
          style="font-size: 18px; font-weight: bold"
        >
          Create User</a-button
        >
      </a-col>
    </a-row>
    <a-divider
      orientation="right"
      style="height: 1px; background-color: #d7d7d7"
    />
  </div>
</template>
<script>
export default {}
</script>
