<template>
  <div>
    <Headings />
    <a-table :columns="columns" :data-source="data" size="middle" />
  </div>
</template>

<script>
import Headings from './components/Header.vue'
export default {
  components: {
    Headings
  },
  data() {
    return {
      columns: [
        { title: 'Name', dataIndex: 'name' },
        { title: 'Age', dataIndex: 'age' },
        { title: 'Address', dataIndex: 'address' }
      ],
      data: [
        {
          key: '1',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park'
        },
        {
          key: '2',
          name: 'Jim Green',
          age: 42,
          address: 'London No. 1 Lake Park'
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
